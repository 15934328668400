var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// A very simple workaround for Big int. Works in conjunction with our custom
// Dataview workaround at ./dataview.ts
Object.defineProperty(exports, "__esModule", {
  value: true
});
const globalObj = typeof globalThis !== "undefined" ? globalThis : typeof _global !== "undefined" ? _global : {};
exports.BigIntPolyfill = typeof BigInt !== "undefined" ? BigInt : globalObj.BigInt || Number;
export default exports;