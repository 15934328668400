import _bigint from "./polyfills/bigint";
var exports = {};

/*

This project is based from the Node implementation made by Gus Caplan
https://github.com/devsnek/node-wasi
However, JavaScript WASI is focused on:
 * Bringing WASI to the Browsers
 * Make easy to plug different filesystems
 * Provide a type-safe api using Typescript


Copyright 2019 Gus Caplan

Permission is hereby granted, free of charge, to any person obtaining a copy
of this software and associated documentation files (the "Software"), to
deal in the Software without restriction, including without limitation the
rights to use, copy, modify, merge, publish, distribute, sublicense, and/or
sell copies of the Software, and to permit persons to whom the Software is
furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in
all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
IN THE SOFTWARE.

 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
const bigint_1 = _bigint;
exports.WASI_ESUCCESS = 0;
exports.WASI_E2BIG = 1;
exports.WASI_EACCES = 2;
exports.WASI_EADDRINUSE = 3;
exports.WASI_EADDRNOTAVAIL = 4;
exports.WASI_EAFNOSUPPORT = 5;
exports.WASI_EAGAIN = 6;
exports.WASI_EALREADY = 7;
exports.WASI_EBADF = 8;
exports.WASI_EBADMSG = 9;
exports.WASI_EBUSY = 10;
exports.WASI_ECANCELED = 11;
exports.WASI_ECHILD = 12;
exports.WASI_ECONNABORTED = 13;
exports.WASI_ECONNREFUSED = 14;
exports.WASI_ECONNRESET = 15;
exports.WASI_EDEADLK = 16;
exports.WASI_EDESTADDRREQ = 17;
exports.WASI_EDOM = 18;
exports.WASI_EDQUOT = 19;
exports.WASI_EEXIST = 20;
exports.WASI_EFAULT = 21;
exports.WASI_EFBIG = 22;
exports.WASI_EHOSTUNREACH = 23;
exports.WASI_EIDRM = 24;
exports.WASI_EILSEQ = 25;
exports.WASI_EINPROGRESS = 26;
exports.WASI_EINTR = 27;
exports.WASI_EINVAL = 28;
exports.WASI_EIO = 29;
exports.WASI_EISCONN = 30;
exports.WASI_EISDIR = 31;
exports.WASI_ELOOP = 32;
exports.WASI_EMFILE = 33;
exports.WASI_EMLINK = 34;
exports.WASI_EMSGSIZE = 35;
exports.WASI_EMULTIHOP = 36;
exports.WASI_ENAMETOOLONG = 37;
exports.WASI_ENETDOWN = 38;
exports.WASI_ENETRESET = 39;
exports.WASI_ENETUNREACH = 40;
exports.WASI_ENFILE = 41;
exports.WASI_ENOBUFS = 42;
exports.WASI_ENODEV = 43;
exports.WASI_ENOENT = 44;
exports.WASI_ENOEXEC = 45;
exports.WASI_ENOLCK = 46;
exports.WASI_ENOLINK = 47;
exports.WASI_ENOMEM = 48;
exports.WASI_ENOMSG = 49;
exports.WASI_ENOPROTOOPT = 50;
exports.WASI_ENOSPC = 51;
exports.WASI_ENOSYS = 52;
exports.WASI_ENOTCONN = 53;
exports.WASI_ENOTDIR = 54;
exports.WASI_ENOTEMPTY = 55;
exports.WASI_ENOTRECOVERABLE = 56;
exports.WASI_ENOTSOCK = 57;
exports.WASI_ENOTSUP = 58;
exports.WASI_ENOTTY = 59;
exports.WASI_ENXIO = 60;
exports.WASI_EOVERFLOW = 61;
exports.WASI_EOWNERDEAD = 62;
exports.WASI_EPERM = 63;
exports.WASI_EPIPE = 64;
exports.WASI_EPROTO = 65;
exports.WASI_EPROTONOSUPPORT = 66;
exports.WASI_EPROTOTYPE = 67;
exports.WASI_ERANGE = 68;
exports.WASI_EROFS = 69;
exports.WASI_ESPIPE = 70;
exports.WASI_ESRCH = 71;
exports.WASI_ESTALE = 72;
exports.WASI_ETIMEDOUT = 73;
exports.WASI_ETXTBSY = 74;
exports.WASI_EXDEV = 75;
exports.WASI_ENOTCAPABLE = 76;
exports.WASI_SIGABRT = 0;
exports.WASI_SIGALRM = 1;
exports.WASI_SIGBUS = 2;
exports.WASI_SIGCHLD = 3;
exports.WASI_SIGCONT = 4;
exports.WASI_SIGFPE = 5;
exports.WASI_SIGHUP = 6;
exports.WASI_SIGILL = 7;
exports.WASI_SIGINT = 8;
exports.WASI_SIGKILL = 9;
exports.WASI_SIGPIPE = 10;
exports.WASI_SIGQUIT = 11;
exports.WASI_SIGSEGV = 12;
exports.WASI_SIGSTOP = 13;
exports.WASI_SIGTERM = 14;
exports.WASI_SIGTRAP = 15;
exports.WASI_SIGTSTP = 16;
exports.WASI_SIGTTIN = 17;
exports.WASI_SIGTTOU = 18;
exports.WASI_SIGURG = 19;
exports.WASI_SIGUSR1 = 20;
exports.WASI_SIGUSR2 = 21;
exports.WASI_SIGVTALRM = 22;
exports.WASI_SIGXCPU = 23;
exports.WASI_SIGXFSZ = 24;
exports.WASI_FILETYPE_UNKNOWN = 0;
exports.WASI_FILETYPE_BLOCK_DEVICE = 1;
exports.WASI_FILETYPE_CHARACTER_DEVICE = 2;
exports.WASI_FILETYPE_DIRECTORY = 3;
exports.WASI_FILETYPE_REGULAR_FILE = 4;
exports.WASI_FILETYPE_SOCKET_DGRAM = 5;
exports.WASI_FILETYPE_SOCKET_STREAM = 6;
exports.WASI_FILETYPE_SYMBOLIC_LINK = 7;
exports.WASI_FDFLAG_APPEND = 1;
exports.WASI_FDFLAG_DSYNC = 2;
exports.WASI_FDFLAG_NONBLOCK = 4;
exports.WASI_FDFLAG_RSYNC = 8;
exports.WASI_FDFLAG_SYNC = 16;
exports.WASI_RIGHT_FD_DATASYNC = bigint_1.BigIntPolyfill(1);
exports.WASI_RIGHT_FD_READ = bigint_1.BigIntPolyfill(2);
exports.WASI_RIGHT_FD_SEEK = bigint_1.BigIntPolyfill(4);
exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS = bigint_1.BigIntPolyfill(8);
exports.WASI_RIGHT_FD_SYNC = bigint_1.BigIntPolyfill(16);
exports.WASI_RIGHT_FD_TELL = bigint_1.BigIntPolyfill(32);
exports.WASI_RIGHT_FD_WRITE = bigint_1.BigIntPolyfill(64);
exports.WASI_RIGHT_FD_ADVISE = bigint_1.BigIntPolyfill(128);
exports.WASI_RIGHT_FD_ALLOCATE = bigint_1.BigIntPolyfill(256);
exports.WASI_RIGHT_PATH_CREATE_DIRECTORY = bigint_1.BigIntPolyfill(512);
exports.WASI_RIGHT_PATH_CREATE_FILE = bigint_1.BigIntPolyfill(1024);
exports.WASI_RIGHT_PATH_LINK_SOURCE = bigint_1.BigIntPolyfill(2048);
exports.WASI_RIGHT_PATH_LINK_TARGET = bigint_1.BigIntPolyfill(4096);
exports.WASI_RIGHT_PATH_OPEN = bigint_1.BigIntPolyfill(8192);
exports.WASI_RIGHT_FD_READDIR = bigint_1.BigIntPolyfill(16384);
exports.WASI_RIGHT_PATH_READLINK = bigint_1.BigIntPolyfill(32768);
exports.WASI_RIGHT_PATH_RENAME_SOURCE = bigint_1.BigIntPolyfill(65536);
exports.WASI_RIGHT_PATH_RENAME_TARGET = bigint_1.BigIntPolyfill(131072);
exports.WASI_RIGHT_PATH_FILESTAT_GET = bigint_1.BigIntPolyfill(262144);
exports.WASI_RIGHT_PATH_FILESTAT_SET_SIZE = bigint_1.BigIntPolyfill(524288);
exports.WASI_RIGHT_PATH_FILESTAT_SET_TIMES = bigint_1.BigIntPolyfill(1048576);
exports.WASI_RIGHT_FD_FILESTAT_GET = bigint_1.BigIntPolyfill(2097152);
exports.WASI_RIGHT_FD_FILESTAT_SET_SIZE = bigint_1.BigIntPolyfill(4194304);
exports.WASI_RIGHT_FD_FILESTAT_SET_TIMES = bigint_1.BigIntPolyfill(8388608);
exports.WASI_RIGHT_PATH_SYMLINK = bigint_1.BigIntPolyfill(16777216);
exports.WASI_RIGHT_PATH_REMOVE_DIRECTORY = bigint_1.BigIntPolyfill(33554432);
exports.WASI_RIGHT_PATH_UNLINK_FILE = bigint_1.BigIntPolyfill(67108864);
exports.WASI_RIGHT_POLL_FD_READWRITE = bigint_1.BigIntPolyfill(134217728);
exports.WASI_RIGHT_SOCK_SHUTDOWN = bigint_1.BigIntPolyfill(268435456);
exports.RIGHTS_ALL = exports.WASI_RIGHT_FD_DATASYNC | exports.WASI_RIGHT_FD_READ | exports.WASI_RIGHT_FD_SEEK | exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS | exports.WASI_RIGHT_FD_SYNC | exports.WASI_RIGHT_FD_TELL | exports.WASI_RIGHT_FD_WRITE | exports.WASI_RIGHT_FD_ADVISE | exports.WASI_RIGHT_FD_ALLOCATE | exports.WASI_RIGHT_PATH_CREATE_DIRECTORY | exports.WASI_RIGHT_PATH_CREATE_FILE | exports.WASI_RIGHT_PATH_LINK_SOURCE | exports.WASI_RIGHT_PATH_LINK_TARGET | exports.WASI_RIGHT_PATH_OPEN | exports.WASI_RIGHT_FD_READDIR | exports.WASI_RIGHT_PATH_READLINK | exports.WASI_RIGHT_PATH_RENAME_SOURCE | exports.WASI_RIGHT_PATH_RENAME_TARGET | exports.WASI_RIGHT_PATH_FILESTAT_GET | exports.WASI_RIGHT_PATH_FILESTAT_SET_SIZE | exports.WASI_RIGHT_PATH_FILESTAT_SET_TIMES | exports.WASI_RIGHT_FD_FILESTAT_GET | exports.WASI_RIGHT_FD_FILESTAT_SET_TIMES | exports.WASI_RIGHT_FD_FILESTAT_SET_SIZE | exports.WASI_RIGHT_PATH_SYMLINK | exports.WASI_RIGHT_PATH_UNLINK_FILE | exports.WASI_RIGHT_PATH_REMOVE_DIRECTORY | exports.WASI_RIGHT_POLL_FD_READWRITE | exports.WASI_RIGHT_SOCK_SHUTDOWN;
exports.RIGHTS_BLOCK_DEVICE_BASE = exports.RIGHTS_ALL;
exports.RIGHTS_BLOCK_DEVICE_INHERITING = exports.RIGHTS_ALL;
exports.RIGHTS_CHARACTER_DEVICE_BASE = exports.RIGHTS_ALL;
exports.RIGHTS_CHARACTER_DEVICE_INHERITING = exports.RIGHTS_ALL;
exports.RIGHTS_REGULAR_FILE_BASE = exports.WASI_RIGHT_FD_DATASYNC | exports.WASI_RIGHT_FD_READ | exports.WASI_RIGHT_FD_SEEK | exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS | exports.WASI_RIGHT_FD_SYNC | exports.WASI_RIGHT_FD_TELL | exports.WASI_RIGHT_FD_WRITE | exports.WASI_RIGHT_FD_ADVISE | exports.WASI_RIGHT_FD_ALLOCATE | exports.WASI_RIGHT_FD_FILESTAT_GET | exports.WASI_RIGHT_FD_FILESTAT_SET_SIZE | exports.WASI_RIGHT_FD_FILESTAT_SET_TIMES | exports.WASI_RIGHT_POLL_FD_READWRITE;
exports.RIGHTS_REGULAR_FILE_INHERITING = bigint_1.BigIntPolyfill(0);
exports.RIGHTS_DIRECTORY_BASE = exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS | exports.WASI_RIGHT_FD_SYNC | exports.WASI_RIGHT_FD_ADVISE | exports.WASI_RIGHT_PATH_CREATE_DIRECTORY | exports.WASI_RIGHT_PATH_CREATE_FILE | exports.WASI_RIGHT_PATH_LINK_SOURCE | exports.WASI_RIGHT_PATH_LINK_TARGET | exports.WASI_RIGHT_PATH_OPEN | exports.WASI_RIGHT_FD_READDIR | exports.WASI_RIGHT_PATH_READLINK | exports.WASI_RIGHT_PATH_RENAME_SOURCE | exports.WASI_RIGHT_PATH_RENAME_TARGET | exports.WASI_RIGHT_PATH_FILESTAT_GET | exports.WASI_RIGHT_PATH_FILESTAT_SET_SIZE | exports.WASI_RIGHT_PATH_FILESTAT_SET_TIMES | exports.WASI_RIGHT_FD_FILESTAT_GET | exports.WASI_RIGHT_FD_FILESTAT_SET_TIMES | exports.WASI_RIGHT_PATH_SYMLINK | exports.WASI_RIGHT_PATH_UNLINK_FILE | exports.WASI_RIGHT_PATH_REMOVE_DIRECTORY | exports.WASI_RIGHT_POLL_FD_READWRITE;
exports.RIGHTS_DIRECTORY_INHERITING = exports.RIGHTS_DIRECTORY_BASE | exports.RIGHTS_REGULAR_FILE_BASE;
exports.RIGHTS_SOCKET_BASE = exports.WASI_RIGHT_FD_READ | exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS | exports.WASI_RIGHT_FD_WRITE | exports.WASI_RIGHT_FD_FILESTAT_GET | exports.WASI_RIGHT_POLL_FD_READWRITE | exports.WASI_RIGHT_SOCK_SHUTDOWN;
exports.RIGHTS_SOCKET_INHERITING = exports.RIGHTS_ALL;
exports.RIGHTS_TTY_BASE = exports.WASI_RIGHT_FD_READ | exports.WASI_RIGHT_FD_FDSTAT_SET_FLAGS | exports.WASI_RIGHT_FD_WRITE | exports.WASI_RIGHT_FD_FILESTAT_GET | exports.WASI_RIGHT_POLL_FD_READWRITE;
exports.RIGHTS_TTY_INHERITING = bigint_1.BigIntPolyfill(0);
exports.WASI_CLOCK_REALTIME = 0;
exports.WASI_CLOCK_MONOTONIC = 1;
exports.WASI_CLOCK_PROCESS_CPUTIME_ID = 2;
exports.WASI_CLOCK_THREAD_CPUTIME_ID = 3;
exports.WASI_EVENTTYPE_CLOCK = 0;
exports.WASI_EVENTTYPE_FD_READ = 1;
exports.WASI_EVENTTYPE_FD_WRITE = 2;
exports.WASI_FILESTAT_SET_ATIM = 1 << 0;
exports.WASI_FILESTAT_SET_ATIM_NOW = 1 << 1;
exports.WASI_FILESTAT_SET_MTIM = 1 << 2;
exports.WASI_FILESTAT_SET_MTIM_NOW = 1 << 3;
exports.WASI_O_CREAT = 1 << 0;
exports.WASI_O_DIRECTORY = 1 << 1;
exports.WASI_O_EXCL = 1 << 2;
exports.WASI_O_TRUNC = 1 << 3;
exports.WASI_PREOPENTYPE_DIR = 0;
exports.WASI_DIRCOOKIE_START = 0;
exports.WASI_STDIN_FILENO = 0;
exports.WASI_STDOUT_FILENO = 1;
exports.WASI_STDERR_FILENO = 2;
exports.WASI_WHENCE_SET = 0;
exports.WASI_WHENCE_CUR = 1;
exports.WASI_WHENCE_END = 2; // http://man7.org/linux/man-pages/man3/errno.3.html

exports.ERROR_MAP = {
  E2BIG: exports.WASI_E2BIG,
  EACCES: exports.WASI_EACCES,
  EADDRINUSE: exports.WASI_EADDRINUSE,
  EADDRNOTAVAIL: exports.WASI_EADDRNOTAVAIL,
  EAFNOSUPPORT: exports.WASI_EAFNOSUPPORT,
  EALREADY: exports.WASI_EALREADY,
  EAGAIN: exports.WASI_EAGAIN,
  // EBADE: WASI_EBADE,
  EBADF: exports.WASI_EBADF,
  // EBADFD: WASI_EBADFD,
  EBADMSG: exports.WASI_EBADMSG,
  // EBADR: WASI_EBADR,
  // EBADRQC: WASI_EBADRQC,
  // EBADSLT: WASI_EBADSLT,
  EBUSY: exports.WASI_EBUSY,
  ECANCELED: exports.WASI_ECANCELED,
  ECHILD: exports.WASI_ECHILD,
  // ECHRNG: WASI_ECHRNG,
  // ECOMM: WASI_ECOMM,
  ECONNABORTED: exports.WASI_ECONNABORTED,
  ECONNREFUSED: exports.WASI_ECONNREFUSED,
  ECONNRESET: exports.WASI_ECONNRESET,
  EDEADLOCK: exports.WASI_EDEADLK,
  EDESTADDRREQ: exports.WASI_EDESTADDRREQ,
  EDOM: exports.WASI_EDOM,
  EDQUOT: exports.WASI_EDQUOT,
  EEXIST: exports.WASI_EEXIST,
  EFAULT: exports.WASI_EFAULT,
  EFBIG: exports.WASI_EFBIG,
  EHOSTDOWN: exports.WASI_EHOSTUNREACH,
  EHOSTUNREACH: exports.WASI_EHOSTUNREACH,
  // EHWPOISON: WASI_EHWPOISON,
  EIDRM: exports.WASI_EIDRM,
  EILSEQ: exports.WASI_EILSEQ,
  EINPROGRESS: exports.WASI_EINPROGRESS,
  EINTR: exports.WASI_EINTR,
  EINVAL: exports.WASI_EINVAL,
  EIO: exports.WASI_EIO,
  EISCONN: exports.WASI_EISCONN,
  EISDIR: exports.WASI_EISDIR,
  ELOOP: exports.WASI_ELOOP,
  EMFILE: exports.WASI_EMFILE,
  EMLINK: exports.WASI_EMLINK,
  EMSGSIZE: exports.WASI_EMSGSIZE,
  EMULTIHOP: exports.WASI_EMULTIHOP,
  ENAMETOOLONG: exports.WASI_ENAMETOOLONG,
  ENETDOWN: exports.WASI_ENETDOWN,
  ENETRESET: exports.WASI_ENETRESET,
  ENETUNREACH: exports.WASI_ENETUNREACH,
  ENFILE: exports.WASI_ENFILE,
  ENOBUFS: exports.WASI_ENOBUFS,
  ENODEV: exports.WASI_ENODEV,
  ENOENT: exports.WASI_ENOENT,
  ENOEXEC: exports.WASI_ENOEXEC,
  ENOLCK: exports.WASI_ENOLCK,
  ENOLINK: exports.WASI_ENOLINK,
  ENOMEM: exports.WASI_ENOMEM,
  ENOMSG: exports.WASI_ENOMSG,
  ENOPROTOOPT: exports.WASI_ENOPROTOOPT,
  ENOSPC: exports.WASI_ENOSPC,
  ENOSYS: exports.WASI_ENOSYS,
  ENOTCONN: exports.WASI_ENOTCONN,
  ENOTDIR: exports.WASI_ENOTDIR,
  ENOTEMPTY: exports.WASI_ENOTEMPTY,
  ENOTRECOVERABLE: exports.WASI_ENOTRECOVERABLE,
  ENOTSOCK: exports.WASI_ENOTSOCK,
  ENOTTY: exports.WASI_ENOTTY,
  ENXIO: exports.WASI_ENXIO,
  EOVERFLOW: exports.WASI_EOVERFLOW,
  EOWNERDEAD: exports.WASI_EOWNERDEAD,
  EPERM: exports.WASI_EPERM,
  EPIPE: exports.WASI_EPIPE,
  EPROTO: exports.WASI_EPROTO,
  EPROTONOSUPPORT: exports.WASI_EPROTONOSUPPORT,
  EPROTOTYPE: exports.WASI_EPROTOTYPE,
  ERANGE: exports.WASI_ERANGE,
  EROFS: exports.WASI_EROFS,
  ESPIPE: exports.WASI_ESPIPE,
  ESRCH: exports.WASI_ESRCH,
  ESTALE: exports.WASI_ESTALE,
  ETIMEDOUT: exports.WASI_ETIMEDOUT,
  ETXTBSY: exports.WASI_ETXTBSY,
  EXDEV: exports.WASI_EXDEV
};
exports.SIGNAL_MAP = {
  [exports.WASI_SIGHUP]: "SIGHUP",
  [exports.WASI_SIGINT]: "SIGINT",
  [exports.WASI_SIGQUIT]: "SIGQUIT",
  [exports.WASI_SIGILL]: "SIGILL",
  [exports.WASI_SIGTRAP]: "SIGTRAP",
  [exports.WASI_SIGABRT]: "SIGABRT",
  [exports.WASI_SIGBUS]: "SIGBUS",
  [exports.WASI_SIGFPE]: "SIGFPE",
  [exports.WASI_SIGKILL]: "SIGKILL",
  [exports.WASI_SIGUSR1]: "SIGUSR1",
  [exports.WASI_SIGSEGV]: "SIGSEGV",
  [exports.WASI_SIGUSR2]: "SIGUSR2",
  [exports.WASI_SIGPIPE]: "SIGPIPE",
  [exports.WASI_SIGALRM]: "SIGALRM",
  [exports.WASI_SIGTERM]: "SIGTERM",
  [exports.WASI_SIGCHLD]: "SIGCHLD",
  [exports.WASI_SIGCONT]: "SIGCONT",
  [exports.WASI_SIGSTOP]: "SIGSTOP",
  [exports.WASI_SIGTSTP]: "SIGTSTP",
  [exports.WASI_SIGTTIN]: "SIGTTIN",
  [exports.WASI_SIGTTOU]: "SIGTTOU",
  [exports.WASI_SIGURG]: "SIGURG",
  [exports.WASI_SIGXCPU]: "SIGXCPU",
  [exports.WASI_SIGXFSZ]: "SIGXFSZ",
  [exports.WASI_SIGVTALRM]: "SIGVTALRM"
};
export default exports;