var exports = {};
// hrtime polyfill for the browser
Object.defineProperty(exports, "__esModule", {
  value: true
});
const baseNow = Math.floor((Date.now() - performance.now()) * 0.001);

function hrtime(previousTimestamp) {
  // initilaize our variables
  let clocktime = performance.now() * 0.001;
  let seconds = Math.floor(clocktime) + baseNow;
  let nanoseconds = Math.floor(clocktime % 1 * 1000000000); // Compare to the prvious timestamp if we have one

  if (previousTimestamp) {
    seconds = seconds - previousTimestamp[0];
    nanoseconds = nanoseconds - previousTimestamp[1];

    if (nanoseconds < 0) {
      seconds--;
      nanoseconds += 1000000000;
    }
  } // Return our seconds tuple


  return [seconds, nanoseconds];
}

exports.default = hrtime;
export default exports;