import _randomfill from "randomfill";
import _browserHrtime from "../polyfills/browser-hrtime";
import _pathBrowserify from "path-browserify";
import _index from "../index";
import _hrtime from "../polyfills/hrtime.bigint";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
}); // @ts-ignore

const randomfill = _randomfill;
const browser_hrtime_1 = _browserHrtime; // @ts-ignore

const path = _pathBrowserify;
const index_1 = _index;
const hrtime_bigint_1 = _hrtime;
const bindings = {
  hrtime: hrtime_bigint_1.default(browser_hrtime_1.default),
  exit: code => {
    throw new index_1.WASIExitError(code);
  },
  kill: signal => {
    throw new index_1.WASIKillError(signal);
  },
  // @ts-ignore
  randomFillSync: randomfill.randomFillSync,
  isTTY: () => true,
  path: path,
  // Let the user attach the fs at runtime
  fs: null
};
exports.default = bindings;
export default exports;
export const __esModule = exports.__esModule;